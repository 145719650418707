import React, { useEffect, useRef } from "react";
import { PageWrapper } from "./page.styles";
import { useNavigate } from "react-router-dom";

import ErrorPage from "../../Pages/errorpage/errorpage";

const Page = ({ data }) => {
  const ref = useRef(null);
  useLinkClickHandlers(ref);
  const location = window.location.pathname;
  // const data = state.source.get(state.router.link);
  // const page = state.source[data.type][data.id];

  const page = Object.entries(data).filter((el) => {
    if (location.slice(1) === el[1].slug) {
      return el;
    }
  });

  // console.log("PAGE", page);

  // const Html2React = libraries.html2react.Component;

  return (
    <>
      {page && page.length > 0 ? (
        <PageWrapper>
          <div
            dangerouslySetInnerHTML={{ __html: page[0][1].content.rendered }}
            ref={ref}
          />
          {/* <Html2React html={page.content.rendered} /> */}
        </PageWrapper>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};

export default Page;

function useLinkClickHandlers(ref) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!ref.current) return;

    const links = ref.current.querySelectorAll("a");
    links.forEach((link) => link.addEventListener("click", handleLinkClick));

    return () => {
      links.forEach((link) =>
        link.removeEventListener("click", handleLinkClick)
      );
    };

    function handleLinkClick(event) {
      const link = event.currentTarget;
      const href = link.getAttribute("href");
      const target = link.getAttribute("target");
      const url = new URL(href || "", window.location.origin);

      const isInternalLink = url.origin === window.location.origin;
      const isOpenedInSameWindow = !target || target === "_self";
      const isLeftButtonClick = event.button === 0;

      // E.g. Ctrl-clicking a link opens it in a new tab
      // so let the browser handle such clicks
      const isModifierKeyPressed =
        event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;

      if (
        isInternalLink &&
        isOpenedInSameWindow &&
        isLeftButtonClick &&
        !isModifierKeyPressed
      ) {
        event.preventDefault();
        navigate(url.pathname + url.search + url.hash);
      }
    }
  }, [navigate, ref]);
}
