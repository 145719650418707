const boardList = [
  {
    id: 2,
    Name: "Larry Lopez",
    Title: "Chair",
    Description: "Workbridge, LLC",
    Image: require("../static/images/board/larry-l.jpg"),
    email: "larry.lopez@workbridgellc.com",
  },
  {
    id: 3,
    Name: "Frank Murphy",
    Title: "Vice Chair",
    Description: "TEI Electrical Solutions",
    Image: require("../static/images/board/frank-m.jpg"),
    email: "Frank.Murphy@teiconst.com",
  },
  {
    id: 18,
    Name: "Brandon Proescher",
    Title: "Secretary",
    Description: "Live Green Landscape Associates, LLC",
    Image: require("../static/images/board/brandon-p.jpg"),
    email: "bproescher@livegreenlandscapes.com",
  },
  {
    id: 5,
    Name: "Anita Sheckells",
    Title: "Treasurer",
    Description: "KatzAbosch",
    Image: require("../static/images/board/anita-s.jpg"),
    email: "asheckells@katzabosch.com",
  },
  {
    id: 6,
    Name: "Kate Lawrence",
    Title: "Chapter Attorney",
    Description: "Lawrence Law, LLC",
    Image: require("../static/images/board/kate-l.jpg"),
    email: "kate@lawrencelawllc.com",
  },
  {
    id: 1,
    Name: "Ted Jasinski",
    Title: "Past Chair",
    Description: "CORE Design Group",
    Image: require("../static/images/board/ted-j.jpg"),
    email: "tjasinski@core-designgroup.com",
  },
  // {
  //   "id":9,
  //   "Name":"Ronnie Brouillard",
  //   "Title":"",
  //   "Description":"Chesapeake Contracting Group",
  //   "Image":require("../static/images/board/ronnie-b.jpg"),
  //   "email":"RBrouillard@ccgmd.com"
  // },
  {
    id: 10,
    Name: "Barnett Carroll",
    Title: "",
    Description: "Aegis Mechanical",
    Image: require("../static/images/board/barnett-c.jpg"),
    email: "barnettc@aegismechanical.com",
  },
  {
    id: 4,
    Name: "John Diehl",
    Title: "",
    Description: "Southway Builders, Inc.",
    Image: require("../static/images/board/john-d.jpg"),
    email: "john@southwaybuilders.com",
  },
  {
    id: 11,
    Name: "Ellie Cascio",
    Title: "",
    Description: "RE Management Solutions",
    Image: require("../static/images/board/ellen-c.jpg"),
    email: "ecascio@gorems.com",
  },
  {
    id: 12,
    Name: "Doug Copp",
    Title: "",
    Description: "Kinsley Construction",
    Image: require("../static/images/board/doug-c.jpg"),
    email: "dcopp@kinsleyconstruction.com",
  },
  {
    id: 4,
    Name: "John Diehl",
    Title: "",
    Description: "Southway Builders, Inc.",
    Image: require("../static/images/board/john-d.jpg"),
    email: "john@southwaybuilders.com",
  },
  {
    id: 22,
    Name: "Kelly Ennis",
    Title: "",
    Description: "The Verve Partnership",
    Image: require("../static/images/board/Kelly-Ennis.jpg"),
    email: "kennis@thevervepartnership.com",
  },
  {
    id: 13,
    Name: "Adam Hirsch",
    Title: "",
    Description: "Hirsch Electric, LLC",
    Image: require("../static/images/board/adam-h.jpg"),
    email: "ahirsch@hirschelectricllc.com",
  },
  {
    id: 14,
    Name: "Patrick Huang",
    Title: "",
    Description: "Turner Construction Company",
    Image: require("../static/images/board/patrick-h.jpg"),
    email: "phuang@tcco.com",
  },
  {
    id: 23,
    Name: "Mark Huntley",
    Title: "",
    Description: "Anchor Mechanical",
    Image: require("../static/images/board/Mark-Huntley.jpg"),
    email: "mhuntley@anchormech.com",
  },
  {
    id: 24,
    Name: "Gregory Malcolm",
    Title: "",
    Description: "IronShore Contracting",
    Image: require("../static/images/board/Gregory-Malcolm.jpg"),
    email: "gmalcolm2@ironshoregrp.com",
  },
  {
    id: 15,
    Name: "Andrew Marani",
    Title: "",
    Description: "A. R. Marani, LLC",
    Image: require("../static/images/board/andrew-m.jpg"),
    email: "andrewmarani@armarani.com",
  },
  {
    id: 16,
    Name: "Jim Merritt",
    Title: "",
    Description: "Bmore Technology",
    Image: require("../static/images/board/jim-m.jpg"),
    email: "james.merritt@bmoretechnology.com",
  },
  {
    id: 17,
    Name: "Debra Mirabile",
    Title: "",
    Description: "DSM Contracting",
    Image: require("../static/images/board/debra-m.jpg"),
    email: "debra@dsm-contracting.com",
  },
  {
    id: 19,
    Name: "Rick Scheetz",
    Title: "",
    Description: "Gray & Son",
    Image: require("../static/images/board/rick-s.jpg"),
    email: "rscheetz@graynson.com",
  },
  {
    id: 20,
    Name: "Hope Smith (ex officio)",
    Title: "",
    Description: "Johns Hopkins Hospital",
    Image: require("../static/images/board/hope-s.jpg"),
    email: "hsmith76@jhmi.edu",
  },
  {
    id: 21,
    Name: "Vic Victoriano (ex officio)",
    Title: "",
    Description: "Skillforce, Inc.",
    Image: require("../static/images/board/vic-v-bw.jpg"),
    email: "vic@skillforce.com",
  },
];

export default boardList;
