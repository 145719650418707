import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import apprenticeship from "../../static/images/apprenticeship.jpg";
import events from "../../static/images/events.jpg";
import membership from "../../static/images/membership.jpg";
import political from "../../static/images/political.jpg";
import safety from "../../static/images/safety.jpg";
import building from "../../static/images/building.jpg";
import membersonly from "../../static/images/members-only.jpg";
import aluminum from "../../static/images/2.jpg";

import apprenticeshipmobile from "../../static/images/apprenticeship_mobile.jpg";
import eventsmobile from "../../static/images/events_mobile.jpg";
import membershipmobile from "../../static/images/membership_mobile.jpg";
import politicalmobile from "../../static/images/political_mobile.jpg";
import safetymobile from "../../static/images/safety_mobile.jpg";
import membersonlymobile from "../../static/images/membersonly_mobile.jpg";

export const LinkBar = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colors-site-mainTheme);
  height: 75px;
  padding: 0px 40px;
  z-index: 100;
`;

export const RightLinks = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const LinkBarLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 130px;
  color: var(--colors-site-white);
  font-weight: 600;
  padding: 0px 15px;
  transition: 0.5s all;
  &:hover {
    background-color: var(--colors-site-lightAccent);
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: 75px;
  right: 0px;
  width: 80vw;
  height: auto;
  // min-height: 510px;
  background-color: var(--colors-site-white);
  overflow: hidden;
  box-shadow: var(--shadow-elevation-medium-local);
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  height: 100%;
  width: 100%;
  border: 10px solid var(--colors-site-white);
  border-top: none;
  border-bottom: none;

  div {
    overflow: hidden;
  }
`;

export const GridCell = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const TitleBar = styled.div`
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  margin: 20px 0px;
`;

export const MediaTitle = styled.div`
  // color: var(--colors-site-white);
  border-bottom: 1px solid lightgray;
  font-weight: 400;
  font-size: 0.9em;
  width: 30%;
  margin-right: 20%;
  margin-left: 70px;
  height: 40px;

  &.quad {
    width: 25%;
    margin-right: 20px;
    margin-left: 10px;
  }
`;

export const MagazineTile = styled.img`
  width: 100%;
  object-fit: contain;

  &:hover {
    border-left: 5px solid red;
  }
`;

export const VideoDiv = styled.div`
  width: 100vw;
  height: auto;
  // height: 100vh;
  // min-height: 80vh;
  position: relative;
  margin-top: 100px;
  // padding: 50px 0px;
  background-color: hsl(30, 50%, 97%);
  margin: 0 auto;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const Video = styled.video`
  width: 100vw;
  height: auto;
  aspect-ratio: 16/5;
  object-fit: cover;
`;

export const MenuItems = styled.div`
  grid-column: ${(props) => props.gridColumn};
  position: relative;

  &.flex {
    display: flex;
  }

  span {
    position: absolute;
    top: 50px;
    right: 10px;
    height: 350px;
    width: 1px;
    background-color: var(--colors-site-mainTheme);
  }
`;

export const MenuItemContainer = styled.div`
  min-width: 225px;
  margin-left: 35px;
  margin-top: 10px;
  // padding-top: 20px;
  padding-left: 30px;
  // background-color: hsl(0, 0%, 95%);
  height: 32px;
`;

export const MenuLink = styled(Link)`
  p {
    font-size: 0.9em;
    font-weight: 300;
    // border-bottom: 1px solid var(--colors-site-mainTheme);
    padding-left: 3px;

    &:hover {
      border-left: 1px solid red;
    }
  }
`;

export const FeaturedEventContainer = styled.div`
  grid-column: 3/-1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  padding-right: 20px;
  padding-bottom: 30px;
`;

export const FeaturedImage = styled.div`
  object-fit: contain;
  overflow: hidden;
  &:hover {
    border-left: 5px solid red;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
    margin: 10px 0px;
  }
`;

export const MainTagline = styled.h2`
  position: absolute;
  width: 30%;
  color: var(--colors-site-white);
  text-align: left;
  background-color: var(--colors-site-overlay);
  padding: 40px;
  margin-left: 10vw;
  top: 20%;
  left: 0;
`;

export const PictureDiv = styled.div`
  width: 100%;
  padding: 40px 0px 0px 0px;
  text-align: center;
  background-color: var(--colors-site-mainTheme);

  h2 {
    color: var(--colors-site-white);
  }
`;

export const ImageDiv = styled.div`
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  div {
    height: 300px;
  }

  h3 {
    font-size: 2em;
    color: white;
    font-weight: 800;
    text-shadow: black 1px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: 0.5s all;
    &:hover {
      background-color: var(--colors-apprenticeship-overlay);
    }
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ImageBackground = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
`;

export const ValuesDiv = styled.div`
  width: 100vw;
  margin-bottom: 100px;
  margin-top: 40px;
  h2 {
    text-align: center;
  }
`;

export const ValueParagraph = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 15px;

  @media screen and (max-width: 800px) {
    h2 {
      font-size: 1.3em !important;
    }
  }
`;

export const AccentLine = styled.hr`
  margin-left: 0;
  margin-top: -20px;
  width: 800px;
  border: 15px solid var(--colors-site-lightAccent);
  opacity: 1;
  z-index: -1;
  max-width: 100vw;

  &.right {
    float: right;
  }
  &.left {
    float: left;
  }
`;

export const AccentLineSmall = styled.hr`
  margin: 0;
  width: 100px;
  border: 4px solid var(--colors-site-mainTheme);
`;

export const ColumnDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const LeftColumn = styled.div`
  width: 60%;
  padding: 10px 40px;

  @media screen and (max-width: 1100px) {
    width: 80%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const RightColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  height: 100%;
  max-height: 50vh;
  width: 40%;
`;

export const GridImage = styled.img`
  overflow: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
`;

export const InfoWrapper = styled.div`
  background-color: var(--colors-site-mainTheme);
  text-align: center;
  margin-bottom: 100px;
  h2 {
    color: var(--colors-site-white);
  }
  padding-top: 30px;
`;

export const InfographicContainer = styled.div`
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--colors-site-white);

  img {
    padding: 20px;
  }
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;

    img {
      padding: 10px 0px;
    }
  }
`;

export const InfoColumnLeft = styled.div`
  width: 38%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  h2 {
    text-align: center;
  }

  @media screen and (max-width: 1100px) {
    align-items: center;
    width: 70%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const InfoColumnRight = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  @media screen and (max-width: 1100px) {
    align-items: center;
    width: 80%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const BottomHero = styled.div`
  background: url(${building});
  background-position: bottom center;
  padding: 200px 0px;
  padding-right: 100px;

  display: flex;
  align-items: center;
  justify-content: end;

  @media screen and (max-width: 800px) {
    padding: 100px 100px;
  }
`;

export const BottomTag = styled.div`
  background-color: var(--colors-site-mainThemeOverlay);
  text-align: right;
  width: 40%;
  padding: 20px;
  h2 {
    color: white;
  }

  @media screen and (max-width: 1100px) {
    width: 60%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;

    h2 {
      font-size: 1.2em !important;
    }
  }
`;

export const IconContainer = styled.div`
  margin-left: 10px;
  margin-top: 15px;
  cursor: pointer;

  &:hover {
    transition: all 0.3s;
    transform: scale(1.1);
  }
`;

export const IconImage = styled.img`
  width: 40px;
  @media screen and (max-width: 1100px) {
    width: 30px;
  }
`;

export const CarouselDiv = styled.div`
  margin: 0 auto;
  margin-bottom: 7px;
  margin-top: 7px;

  // overflow: hidden;
  @media screen and (min-width: 1100px) {
    width: 90vw;
  }

  // border: 1px solid var(--colors-site-mainTheme);
  // border-right: none;
  // border-left: none;
`;

export const CarouselItemDiv = styled.div`
  padding: 43px 0px;
  margin: 0 auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: var(${(props) => props.imageUrl});

  h4 {
    color: black;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const CarouselImage = styled.img`
  width: 40%;

  @media screen and (max-width: 800px) {
    width: 60%;
  }
`;

export const CarouselContent = styled.div`
  width: 40%;
  height: 200px;
  padding-left: 80px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  @media screen and (max-width: 800px) {
    width: 100%;
    align-items: center;
    padding: 0px;
    height: auto;
  }
`;

export const CarouselText = styled.h3`
  @media screen and (max-width: 1100px) {
    font-size: 1.2em;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const CarouselButton = styled.button`
  margin-top: 10px;
  background-color: var(--colors-site-lightAccent);
  border-radius: 30px;
  width: 230px;
  height: 45px;
  border: none;
  font-size: 1.5em;
  padding: 5px;

  @media screen and (max-width: 1100px) {
    width: 200px;
    font-size: 1.2em;
  }
`;

export const LogoOverlay = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
`;
