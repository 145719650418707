import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import apprenticeship from "../../static/images/apprenticeship.jpg";
import events from "../../static/images/events.jpg";
import membership from "../../static/images/membership.jpg";
import political from "../../static/images/political.jpg";
import safety from "../../static/images/safety.jpg";
import building from "../../static/images/building.jpg";
import membersonly from "../../static/images/members-only.jpg";
import aluminum from "../../static/images/2.jpg";

import apprenticeshipmobile from "../../static/images/apprenticeship_mobile.jpg";
import eventsmobile from "../../static/images/events_mobile.jpg";
import membershipmobile from "../../static/images/membership_mobile.jpg";
import politicalmobile from "../../static/images/political_mobile.jpg";
import safetymobile from "../../static/images/safety_mobile.jpg";
import membersonlymobile from "../../static/images/membersonly_mobile.jpg";

export const LinkBar = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colors-site-mainTheme);
  height: 40px;
  padding: 0px 40px;
  z-index: 100;
  box-shadow: var(--shadow-elevation-medium-local);

  @media screen and (max-width: 1100px) {
    display: none;
    position: relative;
  }
`;

export const RightLinks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkBarLink = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--colors-site-white);
  font-weight: 600;
  transition: 0.5s all;
  position: relative;
  overflow: hidden;
  margin-left: 35px;
  padding-right: 35px;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    bottom: 18%;
    left: 0;
    width: 100%;
    height: 0.12em;
    background-color: var(--colors-site-lightAccent);
    opacity: 1;
    transition: opacity 300ms, transform 300ms;
    transform: translate3d(-100%, 0, 0);
  }

  &:hover::after,
  &:focus::after {
    transform: translate3d(-35px, 0, 0);
  }
`;

export const MenuWrapper = styled.div`
  position: absolute;
  width: 100vw;
  top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuContainer = styled.div`
  padding-top: 5px;
  margin: 0 auto;
  max-width: 80vw;
  height: auto;
  background-color: var(--colors-site-white);
  overflow: hidden;
  box-shadow: var(--shadow-elevation-medium-local);
  &.quad {
    border: 10px solid var(--colors-site-white);
    border-top: none;
    border-bottom: none;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-row-gap: 0px;
  height: 100%;
  width: 100%;

  div {
    overflow: hidden;
  }

  &.quad {
    grid-column-gap: 10px;
  }
`;

export const GridCell = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const TitleBar = styled.div`
  // grid-column: 1/-1;
  display: flex;
  align-items: center;
  margin: 20px 0px;
`;

export const MediaTitle = styled.div`
  font-weight: 600;
  font-size: 0.9em;
  width: 80%;
  margin-left: 40px;
  height: 24px;

  a {
    padding-left: 4px;
    color: var(--colors-site-lightAccent);
  }

  a:hover {
    border-left: 2px solid var(--colors-site-lightAccent);
    padding-left: 2px;
  }

  &.quad {
    width: 25%;
    margin-right: 20px;
    margin-left: 10px;
  }

  &:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    display: block; /* This will put the pseudo element on its own line. */
    // margin: 0 auto; /* This will center the border. */
    width: 60%; /* Change this to whatever width you want. */
    // padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 1px solid lightgray; /* This creates the border. Replace black with whatever color you want. */
  }
`;

export const MagazineTile = styled.div`
  overflow: hidden;
  img {
    width: 100%;
    object-fit: contain;
  }
  margin-bottom: 10px;
  transition: 0.3s all;

  &:hover {
    transform: scale(1.03);
  }
`;

export const MenuItems = styled.div`
  grid-column: ${(props) => props.gridColumn};
  position: relative;

  &.flex {
    display: flex;
  }

  // span {
  //   position: absolute;
  //   top: 50px;
  //   right: 10px;
  //   height: 350px;
  //   width: 1px;
  //   background-color: var(--colors-site-mainTheme);
  // }
`;

// export const MenuItemContainer = styled.div`
//   min-width: 225px;
//   margin-left: 35px;
//   margin-top: 10px;
//   // padding-top: 20px;
//   padding-left: 30px;
//   // background-color: hsl(0, 0%, 95%);
//   height: 32px;
// `;

export const MenuLink = styled(Link)`
  text-decoration: none;
  p {
    font-size: 0.9em;
    font-weight: 300;
    margin-left: 48px;
    line-height: 1.2;
    padding-left: 2px;
    border-left: 2px solid var(--colors-site-white);

    &:hover {
      // padding-left: 2px;
      border-left: 2px solid var(--colors-site-lightAccent);
    }

    trandisition: 0.5s all;
  }
`;

export const FeaturedEventContainer = styled.div`
  grid-column: 2/-1;
  width: 100%;
  max-width: 600px;
  margin-top: -15px;
  height: 100%;
  padding: 15px;
  padding-top: 0px;
`;

export const FeaturedImage = styled.div`
  object-fit: contain;
  overflow: hidden;
  transition: 0.3s all;
  &:hover {
    transform: scale(1.03);
    // border-left: 5px solid red;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
    margin: 10px 0px;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;

  &:hover {
    transition: all 0.3s;
    transform: scale(1.1);
  }
`;

export const IconImage = styled.img`
  width: 25px;
  margin-left: 35px;
  margin-top: -2px;
`;

export const HeaderTopBar = styled.div`
  position: sticky;
  display: flex;
  width: 100vw;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  background: var(--colors-site-white);
  padding: 40px;
  z-index: 100;

  @media screen and (max-width: 1100px) {
    padding: 20px;
    justify-content: space-between;
    position: sticky;
    top: 0;
    height: 75px;
  }
`;

export const HeaderButton = styled.button`
  margin-top: 10px;
  background-color: var(--colors-site-lightAccent);
  border-radius: 30px;
  width: 230px;
  height: 45px;
  border: none;
  font-size: 1.5em;
  padding: 5px;
  color: white;

  @media screen and (max-width: 1100px) {
    width: 150px;
  }
`;

export const HamburgerIcon = styled.div`
  margin-left: 5px;
  font-size: 2em;
  color: var(--colors-site-mainTheme);
  z-index: 5;

  @media screen and (min-width: 1100px) {
    display: none;
  }
`;
